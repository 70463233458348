<template>
  <div class="container w-full mx-auto">
    <div class="bg-white mt-5 p-10 mb-4" data-testid="page-error">
      <div class="text-3xl uppercase font-black font-oswald">
        {{ $t('general.logged-out-title') }}
      </div>
      <div class="text-2lg mt-3">
        {{ $t('general.logged-out-desc') }}
      </div>
      <div class="border-t border-medium-grey mt-10 pt-10">
        <Button
          classList="text-lg"
          :text="$t('general.home')"
          theme="full"
          @click-action="goHome"
        />
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import Button from '@/components/general/Button';
import { RouteName } from '@/enums/Routes';
export default {
  components: {
    Button,
  },
  setup() {
    const goHome = () => {
      router.push({ name: RouteName.ROOT });
    };
    return { goHome };
  },
};
</script>
